import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';


import {
    Container
} from "reactstrap";

import EarRight from '../../assets/img/theme/gauche.jpg'; // Remplacez 'path/to' par le chemin approprié
import EarLeft from '../../assets/img/theme/droite.jpg';  // Remplacez 'path/to' par le chemin approprié
import LinkIcon from '../../assets/img/theme/link.jpg';



import "react-circular-progressbar/dist/styles.css";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderClient from "components/Headers/HeaderClient.js";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'; // Assurez-vous d'avoir installé react-circular-progressbar
import 'react-circular-progressbar/dist/styles.css';


const ClientDashboard = () => {
    const [status, setStatus] = useState("Rdv fixé"); // Gérer le statut sélectionné
    const testAuditifCount = 145; // Exemple: Nombre de tests effectués
    const testAuditifScore = 9.3; // Exemple: Score de sécurité
    const testAuditifBonus = 1465; // Exemple: Bonus
    const location = useLocation();
  const client = location.state ? location.state.client : null;
  const history = useHistory();

    // Gérer le changement de statut
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };
    // État pour l'animation de progression
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setProgress(testAuditifScore * 10); // Animation de progression jusqu'au score réel
        }, 500); // Délai pour rendre l'animation visible
    }, [testAuditifScore]);

    const handleVoirDetails = () => {
        history.push({
          pathname: '/admin/nouveauClient',
          state: { client: client },
        });
      };

      const handleVoirAppareillage = () => {
        history.push({
          pathname: '/admin/nouveauClient',
          state: {
            client: client,
            activeTab: '4', // Indicate that tabId="4" should be active
          },
        });
      };
      
      

    return (

        <>
            <HeaderClient client={client} />
            <Container className="mt--7" fluid>

                <div style={styles.container}>
                    {/* Carte 1 : Parcours Patient */}
                    <div
                        style={{
                            ...styles.card,
                            ...styles.parcoursCard,
                            ...styles.largeCardParcours,
                        }}
                        className="card"
                    >
                        <h2 style={{ ...styles.cardTitle, ...styles.cardTitleTop }}>
                            Parcours Patient
                        </h2>

                        {/* Étapes avec widgets améliorés */}
                        <div style={styles.stepsContainer}>
                            {steps.map((step, index) => (
                                <div key={index} style={styles.step}>
                                    {/* Icônes pour chaque étape */}
                                    <div
                                        style={
                                            index === 0
                                                ? { ...styles.stepNumber, ...styles.activeStep }
                                                : styles.stepNumber
                                        }
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.transform = "scale(1.2)")
                                        } // Agrandissement sur hover
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.transform = "scale(1)")
                                        } // Retour à la taille normale
                                    >
                                        {index === 0 && (
                                            <i className="ni ni-calendar-grid-58" style={{ fontSize: '24px' }} />
                                        )}
                                        {index === 1 && (
                                            <i className="ni ni-headphones" style={{ fontSize: '24px' }} />
                                        )}
                                        {index === 2 && (
                                            <i className="ni ni-single-copy-04" style={{ fontSize: '24px' }} />
                                        )}
                                        {index === 3 && (
                                            <i className="ni ni-settings-gear-65" style={{ fontSize: '24px' }} />
                                        )}
                                    </div>
                                    <div style={styles.stepContent}>
                                        <h3 style={styles.stepTitle}>{step.title}</h3>

                                        <span
                                            style={{
                                                ...styles.statusBadge,
                                                backgroundColor: getStatusColor(step.status),
                                            }}
                                        >
                                            {step.status}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button
                            style={styles.buttonBottom}
                            onClick={handleVoirDetails}
                            onMouseEnter={(e) => handleHover(e)}
                            onMouseLeave={(e) => handleHoverLeave(e)}
                        >
                            Voir détails
                        </button>
                    </div>
                    {/* Carte 2 : Appareillage */}
                    <div
                        style={{ ...styles.card, ...styles.largeCardAppareillage }}
                        className="card"
                    >
                        <h2 style={{ ...styles.cardTitle, ...styles.cardTitleTop }}>
                            Appareillage
                        </h2>
                        <div style={styles.appareillageContainer}>
                            {/* Oreille gauche */}
                            <div
                                style={styles.earCard}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.transform = "scale(1.05)")
                                }
                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                                <h3 style={styles.sectionTitle}>Oreille Gauche</h3>
                                <img src={EarLeft} alt="Oreille Gauche" style={styles.image} />
                                <p><strong>Appareil de type : </strong> Conduction osseuse</p>
                                <p><strong>Modèle : </strong>NAIDA L50 P-R</p>
                            </div>

                            {/* Lien entre les deux oreilles */}
                            <div style={styles.linkContainer}>
                                <img
                                    src={LinkIcon}
                                    alt="Lien entre les oreilles"
                                    style={styles.linkIcon}
                                />
                            </div>
                            {/* Oreille droite */}
                            <div
                                style={styles.earCard}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.transform = "scale(1.05)")
                                }
                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                                <h3 style={styles.sectionTitle}>Oreille Droite</h3>
                                <img src={EarRight} alt="Oreille Droite" style={styles.image} />
                                <p>Appareil de type : Conduction osseuse</p>
                                <p>Modèle : NAIDA L50 P-R</p>
                            </div>
                        </div>
                        <button
                            style={styles.button}
                            onClick={handleVoirAppareillage}
                            onMouseEnter={(e) => handleHover(e)}
                            onMouseLeave={(e) => handleHoverLeave(e)}
                        >
                            Voir appareillage
                        </button>
                    </div>

                    {/* Carte 3 : RDV */}
                    <div
                        style={{ ...styles.card, ...styles.rdvCard, ...styles.smallCardRdv }}
                        className="card"
                    >
                        <div style={styles.cardHeader}>
                            {/* Icône animée de calendrier */}
                            <i className="ni ni-calendar-grid-58 text-info" style={styles.calendarIcon} />
                            <h2 style={styles.cardTitle}>Prochain Rendez-vous</h2>
                        </div>
                        <div style={styles.rdvDetails}>
                            {/* Widget de date */}
                            <div style={styles.dateWidget}>
                                <div style={styles.dateInfo}>
                                    <span style={styles.dateDay}>15</span>
                                    <span style={styles.dateMonth}>Octobre</span>
                                </div>
                                <div style={styles.timeInfo}>
                                    <p style={styles.timeText}>10h00</p>
                                </div>
                            </div>
                            {/* Progress bar pour indiquer le temps restant */}
                            <div style={styles.progressContainerA}>
                                <ProgressBar
                                    now={75}
                                    variant="info"
                                    style={styles.rdvProgressBar}
                                />
                            </div>
                            {/* Bouton futuriste pour gérer le RDV */}
                            <button
                                style={styles.manageButton}
                                onMouseEnter={(e) => handleHover(e)}
                                onMouseLeave={(e) => handleHoverLeave(e)}
                            >
                                Gérer le RDV
                            </button>
                        </div>
                    </div>

                    {/* Carte 4 : Statut Client */}
                    <div
                        style={{
                            ...styles.card,
                            ...styles.smallCardStatut,
                            ...styles.statutCard,
                        }}
                        className="card"
                    >
                        {/* Titre avec une icône professionnelle */}
                        <div style={styles.cardHeader}>
                            <i className="ni ni-bell-55 text-primary" style={styles.titleIcon} />
                            <h2 style={styles.cardTitle}>Statut Client</h2>
                        </div>

                        {/* Sélection du statut */}
                        <div style={styles.selectContainer}>
                            <select
                                id="statusSelect"
                                value={status}
                                onChange={handleStatusChange}
                                style={styles.select}
                            >
                                <option value="Rdv fixé">Rdv fixé</option>
                                <option value="Rdv Annulé">Rdv Annulé</option>
                                <option value="Appareillé">Appareillé</option>
                                <option value="En Livraison">En Livraison</option>
                                <option value="Période d'essai">Période d'essai</option>
                                <option value="Facturé">Facturé</option>
                            </select>
                        </div>

                        {/* Date de mise à jour */}
                        <div style={styles.statusUpdateContainer}>
                            <i className="ni ni-time-alarm text-muted" style={styles.statusUpdateIcon} />
                            <span style={styles.statusDateText}>Mise à jour : 01/09/2024</span>
                        </div>
                    </div>

                    {/*Carte 5 : Historique avec widgets et icônes */}
                    <div
                        style={{
                            ...styles.card,
                            ...styles.smallCardHistorique,
                            ...styles.historiqueCard,
                        }}
                        className="card"
                    >
                        {/* Titre de la carte */}
                        <h2 style={styles.cardTitle}>Historique du Client</h2>

                        {/* Contenu de l'historique */}
                        <div style={styles.historiqueContainer}>
                            <div style={styles.historyItem}>
                                <div style={styles.historyIcon}>
                                    <i className="ni ni-calendar-grid-58" style={{ fontSize: '20px' }} />
                                </div>
                                <div style={styles.historyDetails}>
                                    <span style={styles.historyDate}>15/08/2024</span>
                                    <span style={styles.historyEvent}>Consultation initiale</span>
                                    <span
                                        style={{ ...styles.statusBadge, backgroundColor: "#28a745" }}
                                    >
                                        Terminé
                                    </span>
                                </div>
                            </div>
                            <div style={styles.historyItem}>
                                <div style={styles.historyIcon}>
                                    <i className="ni ni-collection text-teal" style={{ fontSize: '20px' }} />
                                </div>
                                <div style={styles.historyDetails}>
                                    <span style={styles.historyDate}>01/09/2024</span>
                                    <span style={styles.historyEvent}>Bilan orthophonique</span>
                                    <span
                                        style={{ ...styles.statusBadge, backgroundColor: "#ffc107" }}
                                    >
                                        En cours
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Carte Test Auditif */}
                    <div
                        style={{ ...styles.card, ...styles.testAuditifCard }}
                        className="card"
                    >
                        <h2 style={styles.cardTitle}>Test Auditif</h2>

                        {/* Cercle de progression pour le score */}
                        <div style={styles.progressContainer}>
                            <CircularProgressbar
                                value={progress} // Utiliser l'état pour l'animation
                                text={`${testAuditifScore}`}
                                styles={buildStyles({
                                    pathColor: `rgba(0, 208, 132, 0.8)`, // Couleur de la barre légèrement transparente
                                    textColor: '#ffffff',
                                    trailColor: '#2C5364',
                                    pathTransition: 'stroke-dashoffset 2s ease 0s', // Animation douce
                                })}
                            />
                            {/* Placer le label juste en dessous */}
                            <span style={{ ...styles.scoreLabel, marginTop: '10px' }}>Score</span>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

// Fonction pour appliquer un effet de hover sur les boutons
const handleHover = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
    e.currentTarget.style.backgroundImage =
        "linear-gradient(87deg, #005A54 0%, #008C82 100%)";
};

const handleHoverLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.backgroundImage =
        "linear-gradient(87deg, #007D70 0%, #00A89A 100%)";
};

const getStatusColor = (status) => {
    switch (status) {
        case "Terminé":
            return "#28a745"; // Vert
        case "En cours":
            return "#ffc107"; // Jaune
        case "À venir":
            return "#dc3545"; // Rouge
        default:
            return "#007bff"; // Bleu par défaut
    }
};

// Étapes pour "Parcours Patient"
const steps = [
    { title: "Rdv pris", percentage: 100, status: "Terminé" },
    { title: "Bilan auditif", percentage: 80, status: "En cours" },
    { title: "Devis", percentage: 50, status: "En cours" },
    { title: "Appareillage", percentage: 20, status: "À venir" },
];

const styles = {
    container: {
        display: "grid",
        gap: "20px",
        gridTemplateColumns: "repeat(4, 1fr)", // Ajustement de la largeur des colonnes
        gridTemplateRows: "1fr 1fr", // 2 lignes
        gridAutoRows: "minmax(150px, auto)",
        gridTemplateAreas: `
       "parcours parcours appareillage appareillage"
        "rdv statut testAuditif historique"
    `,
        padding: "20px",
        height: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    card: {
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        background: "linear-gradient(135deg, #0A2A43, #27496D)",
        width: "100%",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        cursor: "pointer",
        overflow: "hidden",
        textAlign: "center",
        position: "relative",
    },
    largeCardParcours: {
        gridArea: "parcours", // Cette carte occupe les deux premières colonnes de la première ligne
        backgroundColor: "#141E30", // Fond sombre pour un look moderne
        backgroundImage: "linear-gradient(315deg, #141E30, #243B55)",
        borderRadius: "15px",
        boxShadow: "0 8px 20px rgba(0, 255, 255, 0.2)", // Ombre lumineuse néon
        padding: "20px",
        marginBottom: "20px",
        transition: "transform 0.4s ease-in-out",
        color: "#ffffff",
        minHeight: "100%", // Augmenté
        height: "400px", // Augmenté
        width: "100%", // Assurez-vous que la largeur s'étend à 100% du conteneur

    },

    // Carte "Appareillage"
    largeCardAppareillage: {
        gridArea: "appareillage", // Cette carte occupe la dernière colonne de la première ligne
        height: "450px",
        width: "100%", // Assurez-vous que la largeur s'étend à 100% du conteneur

    },

    // Carte "Prochain Rendez-vous"
    smallCardRdv: {
        gridArea: "rdv", // Première colonne de la deuxième ligne
        height: "270px", // Ajustez la hauteur pour qu'elle tienne
        width: "100%",
    },

    // Carte "Statut Client"
    smallCardStatut: {
        gridArea: "statut", // Deuxième colonne de la deuxième ligne
        height: "270px",
        width: "100%",


    },

    // Carte "Historique"
    smallCardHistorique: {
        gridArea: "historique", // Dernière colonne de la deuxième ligne
        height: "270px",
        width: "100%", // Assurez-vous que la largeur s'étend à 100% du conteneur

    },
    // Carte "testAuditif"
    testAuditifCard: {
        gridArea: "testAuditif", // Emplacement de la carte
        background: 'linear-gradient(135deg, #141E30, #243B55)',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
        color: '#ffffff',
        height: "270px", // Ajuster selon le design
        width: "100%",
        display: 'flex', // Utilisez flex pour le centrage
        alignItems: 'center', // Centre verticalement
        justifyContent: 'center', // Centre horizontalement
        overflow: 'hidden', // Cache tout débordement
    },

    statsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    },
    statBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '45%',
    },
    statNumber: {
        display: 'block',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    statLabel: {
        display: 'block',
        fontSize: '14px',
        color: '#ddd',
    },
    progressContainer: {
        marginTop: '20px',
        width: '60%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column', // Ajoutez cette propriété pour aligner verticalement
        alignItems: 'center', // Centre horizontalement
        justifyContent: 'center', // Centre verticalement
        position: 'relative',
    },
    scoreLabel: {
        display: 'block',
        marginTop: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#00D084',
    },

    parcoursCard: {},
    statutClientCard: {
        padding: "15px",
        borderRadius: "15px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(200, 200, 200, 0.2)",
        backgroundImage: "linear-gradient(135deg, #ffffff, #f0f0f0)", // Dégradé de blanc
        minHeight: "200px", // Hauteur minimale
        height: "200px", // Hauteur fixe
        transition: "transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out",
    },
    cardTitle: {
        fontSize: "1.5em",
        color: "white",
    },
    statutCard: {
        padding: "20px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)", // Effet de profondeur
        border: "1px solid rgba(0, 125, 112, 0.2)", // Bordure subtile
    },
    statusHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        marginBottom: "10px",
    },
    // statusIcon: {
    //   boxShadow: "0 0 10px rgba(0, 168, 154, 0.5)", // Effet lumineux
    //   color: "green",

    // },
    iconCard: {
        display: "inline-block",
        padding: "10px",
        borderRadius: "50%",
        background: "linear-gradient(87deg, #003D33 0%, #007D70 100%)",
        boxShadow: "0 4px 10px rgba(0, 125, 112, 0.5)", // Effet lumineux
    },
    statusText: {
        fontSize: "1.2em",
        fontWeight: "bold",
        color: "white",
    },
    statusUpdateContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        padding: "10px 15px",
        borderRadius: "8px",
        background: "linear-gradient(87deg, #003D33 0%, #007D70 100%)", // Dégradé bleu/vert
        marginTop: "10px",
        color: "#ffffff", // Texte blanc
        fontWeight: "bold",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    },
    titleIcon: {
        marginRight: "5px",
        left: "3.5em",
        color: "#00FFAA", // Vert professionnel
        fontSize: "1.5em",
    },
    selectLabel: {
        marginBottom: "5px",
        fontSize: "1em",
        color: "#007D70",
        fontWeight: "bold",
    },
    select: {
        padding: "10px 15px",
        borderRadius: "8px",
        border: "1px solid #007D70",
        fontSize: "1em",
        cursor: "pointer",
        background: "linear-gradient(135deg, #f0f0f0, #ffffff)",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        outline: "none",
        transition: "border-color 0.3s ease-in-out",
    },
    selectFocus: {
        borderColor: "#00A89A", // Changement de couleur de bordure lors du focus
    },
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "15px",
    },
    statusUpdate: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        marginTop: "20px",
        color: "transparent",
        background: "linear-gradient(87deg, #003D33 0%, #007D70 100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
    },
    statusDateText: {
        fontSize: "0.9em",
        fontWeight: "bold",
    },
    cardTitleTop: {
        marginBottom: "20px",
        marginTop: "0",
    },

    stepsContainer: {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    },
    step: {
        textAlign: "center",
        padding: "10px",
        transition: "all 0.3s ease",
    },
    stepNumber: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        backgroundColor: "#007D70",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto 10px",
        fontSize: "18px",
        transition: "transform 0.2s ease-in-out",
        cursor: "pointer",
    },
    activeStep: {
        border: "3px solid #FFD700",
        boxShadow: "0 0 10px #FFD700",
    },
    stepContent: {
        flexGrow: 1,
        textAlign: "center",
    },
    stepTitle: {
        fontSize: "1.2em",
        margin: "0 0 8px",
        fontWeight: "600",
        color: "#fff", // Changer la couleur du texte en blanc

    },
    appareillageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    earCard: {
        border: "1px solid #007D70",
        borderRadius: "10px",
        padding: "15px",
        margin: "0 10px",
        textAlign: "center",
        transition: "transform 0.3s ease-in-out",
        backgroundColor: "#f9f9f9",
    },
    image: {
        width: "80px",
        height: "auto",
        marginTop: "10px",
        marginBottom: "10px",
    },
    linkContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    linkIcon: {
        width: "40px",
        height: "auto",
    },
    button: {
        marginTop: "40px",
        padding: "10px 12px", // Réduire le padding

        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "12px", // Réduire la taille de la police pour s'adapter à la taille du bouton
        color: "#fff",
        backgroundImage: "linear-gradient(87deg, #007D70 0%, #00A89A 100%)",
        transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
        width: "150px", // Ajouter une largeur fixe pour réduire la taille du bouton
        alignSelf: "center", // Centrer le bouton si nécessaire
    },
    buttonBottom: {
        position: "absolute",
        bottom: "20px",
        left: "40%",

        padding: "10px 12px", // Réduire le padding
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "12px", // Réduire la taille de la police pour s'adapter à la taille du bouton
        color: "#fff",
        backgroundImage: "linear-gradient(87deg, #007D70 0%, #00A89A 100%)",
        transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
        width: "150px", // Ajouter une largeur fixe pour réduire la taille du bouton
    },
    progressCircleContainer: {
        width: "50px",
        height: "50px",
        margin: "10px auto",
    },
    status: {
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "15px",
        color: "#fff",
        marginTop: "10px",
        fontSize: "12px",
    },

    statusBadge: {
        padding: "5px 10px",
        borderRadius: "20px",
        color: "#fff",
        fontWeight: "500",
        fontSize: "0.9em",
        display: "inline-block",
    },
    rdvCard: {
        padding: "15px",
        textAlign: "center",
        backgroundColor: "#1F1F2E",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease-in-out",
        maxWidth: "350px",
        height: "fit-content",
    },
    cardHeader: {
        display: "flex",
        alignItems: "center", // Centre verticalement l'icône et le texte
        justifyContent: "center", // Centre horizontalement l'ensemble
        marginBottom: "10px",
        color: "#fff", // Met le texte en blanc
    },
    calendarIcon: {
        color: "#00FFAA",
        marginRight: "4px", // Espacement entre l'icône et le texte
        fontSize: "1.4em",
    },

    rdvDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    dateWidget: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center", // Centre le contenu du widget
        padding: "8px 15px",
        borderRadius: "8px",
        backgroundImage: "linear-gradient(135deg, #00A89A, #003D33)",
        color: "#fff",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
        marginBottom: "10px",
        width: "80%",
    },
    dateInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    dateDay: {
        fontSize: "22px",
        fontWeight: "bold",
    },
    dateMonth: {
        fontSize: "12px",
        textTransform: "uppercase",
    },
    timeText: {
        fontSize: "14px",
        fontWeight: "bold",
    },
    progressContainerA: {
        width: "70%",
        marginBottom: "12px",
    },
    rdvProgressBar: {
        height: "8px",
        borderRadius: "5px",
    },
    manageButton: {
        marginTop: "5px",
        padding: "5px 14px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "12px",
        color: "#fff",
        backgroundImage: "linear-gradient(87deg, #00FFAA 0%, #007D70 100%)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease, background-color 0.3s ease",
    },
    historiqueCard: {
        padding: "10px",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Ombre douce
        border: "1px solid rgba(0, 0, 0, 0.1)", // Bordure subtile
        // Cache le contenu qui dépasse
        transition: "transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out",
    },

    // Conteneur pour chaque élément historique
    historiqueContainer: {
        marginTop: "15px",
        width: "100%", // Ajuste la largeur du conteneur à 100%
        margin: "0 auto", // Centre horizontalement
        display: "flex", // Utilisez flex pour centrer
        flexDirection: "column", // Place les éléments verticalement
        alignItems: "center", // Centre chaque élément horizontalement
        justifyContent: "center", // Centre le contenu verticalement
    },

    // Un seul élément historique
    historyItem: {
        display: "flex",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: "#f1f1f1",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        width: "80%", // Réduisez la largeur pour centrer
    },

    // Icône pour chaque élément historique
    historyIcon: {
        backgroundColor: "#e0f2f1",
        padding: "6px",
        borderRadius: "50%",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    },

    // Détails pour chaque élément historique
    historyDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: 1,
    },

    // Date de l'événement historique
    historyDate: {
        fontSize: "0.7em", // Réduit la taille de la police
        color: "#007D70",
        fontWeight: "bold",
    },

    // Événement historique (description)
    historyEvent: {
        fontSize: "0.7em", // Réduit la taille de la police
        margin: "3px 0",
        color: "#333",
    },
};

export default ClientDashboard;
