import React, { useState, useEffect }  from "react";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Media
} from "reactstrap";
import {logout} from "../../network/ApiAxios";
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';



// const customStyles = {
//     control: (base, state) => ({
//         ...base,
//         background: "#fff",
//         borderColor: '#ced4da',
//         minHeight: 'calc(1.5em + .75rem + 2px)',
//         height: 'calc(2.25rem + 2px)',
//         boxShadow: state.isFocused ? null : null,
//         '&:hover': {
//             borderColor: state.isFocused ? '#adb5bd' : '#ced4da'
//         }
//     }),
//     valueContainer: (base) => ({
//         ...base,
//         padding: '0 .75rem'
//     }),
//     input: (base) => ({
//         ...base,
//         margin: 0,
//         padding: 0
//     }),
//     indicatorSeparator: (base) => ({
//         ...base,
//         display: 'none'
//     }),
//     dropdownIndicator: (base) => ({
//         ...base,
//         padding: '0 .75rem'
//     }),
//     placeholder: (base) => ({
//         ...base,
//         fontSize: '1rem',
//         color: '#6c757d'
//     }),
// };


  

const AdminNavbar = props => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const history = useHistory();
    let username = JSON.parse(localStorage.getItem("user")).name;

    // const logOut = async () => {
    //     const token = localStorage.getItem("token");
    //     if (token) {
    //         const response = await logout(token);
    //         const { data } = response;
    //         if (data.success) {
    //             localStorage.removeItem("token");
    //             localStorage.removeItem("user");
    //             props.history.push("/auth/login");
    //         }else {
    //             console.error("Échec de la déconnexion");
    //         }
    //     }
    // }

    // const logOut = async (e) => {
    //     e.preventDefault();
    //     console.log("Logout clicked");  // Ajoutez ceci pour tester si le clic fonctionne
    //     const token = localStorage.getItem("token");
    //     if (token) {
    //         const response = await logout(token);
    //         const { data } = response;
    //         if (data.success) {
    //             localStorage.removeItem("token");
    //             localStorage.removeItem("user");
    //             props.history.push("/auth/login");
    //         } else {
    //             console.error("Échec de la déconnexion");
    //         }
    //     }
    // }

    useEffect(() => {
        const fetchClients = async () => {
            const token = localStorage.getItem('token').trim().replace('JWT ', '');
            try {
                const response = await axios.get('https://app.vtalys.fr/api/clients', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (response.data && Array.isArray(response.data)) {
                    const options = response.data.map(client => ({
                        value: client._id,
    label: `${client.nom} ${client.prenom}`, // Affiche uniquement le nom dans les suggestions
    fullLabel: `${client.nom} - ${client.telephonePortable} - ${client.email}`, // Utilise ce champ pour la recherche
    ...client
                    }));
                    setClientOptions(options);
                } else {
                    console.error("Failed to fetch clients or data is not an array:", response.data);
                }
            } catch (error) {
                console.error('Error fetching clients:', error.response ? error.response.data : error.message);
            }
        };

        fetchClients();
    }, []);
    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        return inputValue.length === 0 ? [] : clientOptions.filter(client =>
        //   client.label.toLowerCase().includes(inputValue)
        client.fullLabel.toLowerCase().includes(inputValue)
        );
      };
      
      const getSuggestionValue = suggestion => suggestion.label;
      
      const renderSuggestion = suggestion => (
        <div>
          {suggestion.label}
        </div>
      );
    // const handleInputChange = newValue => {
    //     setInputValue(newValue);
    // };

    // const handleChange = option => {
    //     setSelectedOption(option);
    //     history.push(`/nouveauClient/${option.value}`);
    // };

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (_, { suggestion }) => {
        console.log(`Navigation to client details for ID: ${suggestion.value}`);
        console.log('Client sélectionné dans la barre de recherche:', suggestion);
        history.push({
          pathname: `/admin/nouveauClient`,
          state: { client: suggestion }
        });
        setValue('');
    };
    

    const inputProps = {
        placeholder: 'Rechercher',
        value,
        onChange: onChange,
        style: {
            width: '100%',
            height: 'calc(1.5em + .75rem + 2px)',
            padding: '10px 12px',
            fontFamily: "Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: '1rem',
            border: '1px solid #ced4da', // Customize border color here
            borderRadius: '1rem'
            //background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)'
            
        }
    };

    
    

    // Customize Autosuggest styles
    const theme = {
        container: {
            position: 'relative'
        },
        input: {
            width: '100%',
            height: '38px',
            padding: '10px 20px',
            fontWeight: 300,
            fontSize: '16px',
            border: '1px solid #ced4da', // Customize input border color here
            borderRadius: '4px'
        },
        inputFocused: {
            outline: 'none',
            borderColor: '#80bdff', // Color when the input is focused
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)' // Optional: Bootstrap focus shadow style
        },
        suggestionsContainerOpen: {
            display: 'block',
            position: 'absolute',
            top: '51px',
            width: '100%',
            border: '1px solid #aaa',
            backgroundColor: '#fff',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            zIndex: 2,
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            overflowY: 'auto'
        },
        suggestion: {
            cursor: 'pointer',
            padding: '10px 0',
            overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
        },
        suggestionHighlighted: {
            backgroundColor: '#ddd'
        }
    };

    const logOut = async () => {
        try {
            const token = localStorage.getItem("token");
            if (token) {
                const response = await logout(token);
                const { data } = response;
                if (data.success) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    props.history.push("/auth/login");
                } else {
                    console.error("Échec de la déconnexion:", data.message);
                }
            }
        } catch (error) {
            console.error("Erreur lors de la déconnexion:", error.response ? error.response.data : error);
        }
    }
    
    
    

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                        to="/"
                    >
                        {props.brandText}
                    </Link>
                    {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                        <FormGroup className="mb-0">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-search"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Search" type="text" value={searchTerm} onChange={handleSearchChange}/>
                            </InputGroup>
                        </FormGroup>
                    </Form> */}
                     {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                        <Select
                        styles={customStyles}
                            value={selectedOption}
                            onChange={handleChange}
                            //onInputChange={handleInputChange}
                            options={clientOptions}
                            placeholder="Rechercher"
                            noOptionsMessage={() => 'Aucun client trouvé'}
                            isLoading={clientOptions.length === 0}
                        />
                    </Form> */}

<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            
                           
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    onSuggestionSelected={onSuggestionSelected}
                                    theme={theme}
                                />
                    </Form>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                          alt="..."
                          src={require("assets/img/theme/sketch.jpg").default}
                      />
                    </span>
                                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {username}
                      </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Bienvenue!</h6>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-single-02"/>
                                    <span>Mon profil</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-settings-gear-65"/>
                                    <span>Paramètres</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-calendar-grid-58"/>
                                    <span>Activité</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-support-16"/>
                                    <span>Support</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-badge"/>
                                    <span>Mon Abonnement</span>
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem href="#!" onClick={logOut}>
                                    <i className="ni ni-user-run"/>
                                    <span>Deconnexion</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
