// import React from 'react';
// import {Route, Redirect} from 'react-router-dom';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         localStorage.getItem("token") && localStorage.getItem("user")
//             ? <Component {...props} />
//             : <Redirect to='/auth/login' />
//     )} />
// );

// export default PrivateRoute;
///vrai
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");

  // Log pour vérifier que PrivateRoute s'exécute
  console.log('Exécution de PrivateRoute');
  console.log('Token:', token);
  console.log('User:', user);

  return (
    <Route
      {...rest}
      render={(props) => (
        token && user
          ? <Component {...props} />
          : <Redirect to='/auth/login' />
      )}
    />
  );
};

export default PrivateRoute;


