import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const AppareillagesChart = () => {
  const [chartData, setChartData] = useState({
    labels: [], // Mois
    datasets: [
      {
        label: 'Appareillages',
        data: [], // Données par mois
        backgroundColor: 'rgba(103, 58, 183, 0.7)',
        borderColor: '#673AB7',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(103, 58, 183, 1)',
        hoverBorderColor: '#5E35B1',
      },
    ],
  });

//   useEffect(() => {
//     // Fonction pour récupérer les données du backend
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('/api/clients/appareilles-by-month'); // Ajuste le chemin si nécessaire
//         const appareillesByMonth = response.data.appareillesByMonth;

//         // Mapper les données pour le graphe
//         const labels = [];
//         const data = [];
//         appareillesByMonth.forEach((entry) => {
//           labels.push(`Mois ${entry._id}`); // Par exemple "Mois 1", "Mois 2", ...
//           data.push(entry.count); // Compte des appareillages pour chaque mois
//         });

//         // Mettre à jour les données du graphique
//         setChartData((prevData) => ({
//           ...prevData,
//           labels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data,
//             },
//           ],
//         }));
//       } catch (error) {
//         console.error('Error fetching appareillages by month:', error);
//       }
//     };

//     fetchData();
//   }, []);

useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('token').trim().replace('JWT ', '');
        try {
            // Utiliser une URL absolue si nécessaire
            const response = await axios.get('https://app.vtalys.fr/api/counts/appareilles-by-month', {
                headers: { Authorization: `Bearer ${token}` },
                maxRedirects: 0,
            });
            console.log('Token:', token);

            console.log('API Response:', response.data);
            
            const appareillesByMonth = response.data.appareillesByMonth;

            if (Array.isArray(appareillesByMonth)) {
                const labels = [];
                const data = [];
                appareillesByMonth.forEach((entry) => {
                    const monthName = new Date(entry._id.year, entry._id.month - 1).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
                    labels.push(monthName);
                    data.push(entry.count);
                });
            
                setChartData((prevData) => ({
                    ...prevData,
                    labels,
                    datasets: [
                        {
                            ...prevData.datasets[0],
                            data,
                        },
                    ],
                }));
            } else {
                console.error('Response is not an array:', appareillesByMonth);
            }
            
        } catch (error) {
            if (error.response) {
                console.error('API Error Status:', error.response.status);
                console.error('API Error Data:', error.response.data);
              } else if (error.request) {
                console.error('API Request Error:', error.request);
              } else {
                console.error('General API Error:', error.message);
              }
              
        }
    };

    fetchData();
}, []);

  

  return (
    <Bar
      data={chartData}
      options={{
        animation: {
          duration: 1500,
          easing: 'easeInOutCubic',
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              font: {
                size: 14,
                family: 'Arial',
                weight: 'bold',
              },
              color: '#333',
            },
          },
          tooltip: {
            backgroundColor: '#673AB7',
            titleColor: '#fff',
            bodyColor: '#fff',
          },
        },
      }}
    />
  );
};

export default AppareillagesChart;
