import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { FaUser, FaCalendarAlt } from "react-icons/fa";

class HeaderClient extends React.Component {
  render() {
    // const clientInfo = {
    //   firstName: "Jean",
    //   lastName: "Dupont",
    //   dob: "15/08/1980",
    // };
    const { client } = this.props;

    if (!client) {
      return null; // Or render a placeholder
    }

    // Adjust property names based on your client object structure
    const clientInfo = {
      firstName: client.prenom || "",
      lastName: client.nom || "",
      dob: client.dateNaissance || "",
    };
    const formattedDOB = clientInfo.dob
  ? new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(clientInfo.dob))
  : "Date inconnue";



    return (
      <>
        <div
          className="header pb-8 pt-5 pt-md-8"
          style={{
            background: "linear-gradient(87deg, #003D33 0, #007D70 100%)",
          }}
        >
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row className="justify-content-center">
                <Col md="4">
                  <Card
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                      maxWidth: "300px", // Limite la largeur de la carte
                      margin: "auto",
                    }}
                  >
                    <CardBody
                      style={{
                        background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
                        borderRadius: "10px",
                        padding: "10px", // Réduction supplémentaire du padding
                      }}
                    >
                      <h6 style={{ fontWeight: "bold", color: "#007D70", fontSize: "1em", marginBottom: "5px" }}>
                        <FaUser style={{ marginRight: "5px", color: "#00A89A", fontSize: "0.9em" }} />
                        {clientInfo.firstName} {clientInfo.lastName}
                      </h6>
                      <hr style={{ margin: "5px 0" }} /> {/* Réduit l'espace autour de la ligne */}
                      <div style={{ marginTop: "5px" }}> {/* Réduit la marge supérieure */}
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                          <FaCalendarAlt style={{ marginRight: "5px", color: "#007D70", fontSize: "0.9em" }} />
                          <span style={{ fontSize: "0.9em", fontWeight: "bold" }}>
                            Date de Naissance: {formattedDOB}
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderClient;
